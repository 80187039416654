export const parseSubscanUrl = (originUrl: string) => {
  const splited = originUrl.split(".subscan.io");

  const domain = process.env.NODE_ENV === "production" ? `${splited[0]}.subscan.io` : `${splited[0]}.stg.subscan.io`;
  const path = `${splited[1]}`;
  const url = `${domain}${path}`;

  return { domain, path, url };
};

/**
 * https://www.subscan.io -> subscan.io
 * @param hostname url hostname
 * @returns maindomain
 */
export function getMainDomain(hostname: string): string {
  // Split the hostname into an array
  const hostnameParts: string[] = hostname.split(".");

  // If the number of parts in the hostname is greater than or equal to 3, it typically indicates a subdomain
  if (hostnameParts.length >= 3) {
    // Extract the last two parts to get the main domain
    const mainDomain: string = hostnameParts.slice(-2).join(".");
    return mainDomain;
  } else {
    // Otherwise, the entire hostname is the main domain
    return hostname;
  }
}

/**
 * Checks if a URL is in the redirect allowList.
 * @param url The URL to check.
 * @returns True if the URL is in the allowList, otherwise false.
 */
export function isRedirectUrlAllowListed(url: string): boolean {
  const allowListPatterns = [
    /^https:\/\/[a-zA-Z0-9-]+\.subscan\.io(\/.*)?$/,
    /^https:\/\/subscan\.io(\/.*)?$/,
    /^https:\/\/[a-zA-Z0-9-]+\.stg\.subscan\.io(\/.*)?$/,
    /^https:\/\/[a-zA-Z0-9-]+\.prev\.subscan\.io(\/.*)?$/,
    /^http:\/\/localhost:3009(\/.*)?$/,
    /^http:\/\/localhost:3000(\/.*)?$/,
    /^\/.{1,50}$/,
  ];

  return allowListPatterns.some((pattern) => pattern.test(url));
}
